import React from "react";
import { FaFacebook, FaTwitter, FaInstagram, FaPhone, FaMap, FaEnvelope } from "react-icons/fa";
import { Button } from "rsuite";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import ReactHtmlParser from "react-html-parser";

require("./AaFooter.scss");

class AaFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
  }


  render() {
    var markdown = require("markdown").markdown;
    const newAddressHTML = markdown.toHTML(
      this.props.address.replace(/\\/g, "  ")
    );
    return (
      <footer className="footer">
        <div className="footerAddress hidden">{ReactHtmlParser(newAddressHTML)}</div>
        <footer className="ftco-footer ftco-section">
          <div>
            <div className="columns">
              <div className="column">
                <div className="ftco-footer-widget is-4">
                  <h2 className="ftco-heading-2">Environ Digital</h2>
                  <p>Helping businesses succeed online</p>
                  <div className="footerSocial">
                    <ul>
                      {this.props.socialLinks.facebook ? (
                        <li>
                          <a href={this.props.socialLinks.facebook}>
                            <p>Facebook</p>
                            <FaFacebook size="2em" />
                          </a>
                        </li>
                      ) : (
                          ""
                        )}
                      {this.props.socialLinks.twitter ? (
                        <li>
                          <a href={this.props.socialLinks.twitter}>
                            <p>Twitter</p>
                            <FaTwitter size="2em" />
                          </a>
                        </li>
                      ) : (
                          ""
                        )}
                      {this.props.socialLinks.instagram ? (
                        <li>
                          <a href={this.props.socialLinks.instagram}>
                            <p>Inst</p>
                            <FaInstagram size="2em" />
                          </a>
                        </li>
                      ) : (
                          ""
                        )}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="ftco-footer-widget col-4">
                  <h2 className="ftco-heading-2">Our Services</h2>
                  <ul className="list-unstyled">
                    <li><AniLink paintDrip hex="#3f1682" to="/website-design-and-development" className="py-2 d-block">Websites</AniLink></li>
                    <li><AniLink paintDrip hex="#d68125" to="/social-media-setup-and-content-creation" className="py-2 d-block">Social media</AniLink></li>
                    <li><AniLink paintDrip hex="#938f07" to="/training-and-workshops" className="py-2 d-block">Training</AniLink></li>
                  </ul>
                </div>
              </div>
              <div className="column">
                <div className="ftco-footer-widget col-4">
                  <h2 className="ftco-heading-2">Links</h2>
                  <ul className="list-unstyled">
                    <li><AniLink paintDrip hex="#359dd9" to="/" className="py-2 d-block">Home</AniLink></li>
                    <li><AniLink paintDrip hex="#359dd9" to="/about" className="py-2 d-block">About</AniLink></li>
                    <li><AniLink paintDrip hex="#b40812" to="/our-personal-approach" className="py-2 d-block">Approach</AniLink></li>
                    <li><AniLink paintDrip hex="#359dd9" to="/blog" className="py-2 d-block">Blog</AniLink></li>
                    <li><AniLink paintDrip hex="#3f1682" to="/website-design-and-development#casestudies" className="py-2 d-block">Case Studies</AniLink></li>
                    <li><AniLink paintDrip hex="#359dd9" to="/contact-us" className="py-2 d-block">Contact</AniLink></li>
                  </ul>
                </div>
              </div>
              <div className="column">
                <div className="ftco-footer-widget mb-4">
                  <h2 className="ftco-heading-2">Get in touch</h2>
                  <div className="block-23 mb-3">
                    <ul>
                      {/* <li><FaMap size="1em" /><span className="text"></span></li> */}
                      {/* <li><FaPhone size="1em" /><span className="text"> +447807042123</span></li> */}
                      <li><a href="mailto:hello@environ.digital"><FaEnvelope size="1em" /><span className="text"> hello@environ.digital</span></a></li>
                    </ul>

                    <AniLink paintDrip hex="#359dd9" to="/contact-us">
                      <Button className="btn">Start a project →</Button>
                    </AniLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-center">
              </div>
            </div>
          </div>
        </footer>
      </footer>
    );
  }
}

export default AaFooter;
